.reviewFormPage {
    margin: 40px auto 100px;
    display: flex;
    flex-direction: row;
    gap: 40px;

    .leftrail {
        width: 70%;
    }
    .rightrail {
        width: 380px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 32px;
        margin-bottom: 64px;
        .leftrail {
            width: 100%;
        }
        .rightrail {
            display: none;
        }
    }
}