.ageBiasSection {
    margin: 80px auto;
    margin-bottom: 0;

    .ageBiasSection_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .leftRail {
        width: 70%;
    }
    .rightRail {
        width: 380px;
        z-index: 9;
    }

    .ageBiasSection_main {
        .ageBiasSection_list {
            list-style: none;
            margin-top: 24px;
    
            &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0px;
                }
    
                h3 {
                    font-family: 'SourceSansPro-SemiBold';
                    font-size: 24px;
                    line-height: 30px;
                    color: var(--blue);
                }
                p {
                    padding-left: 12px;
                    font-family: 'SourceSansPro-Regular';
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }
    }

    .quote {
        padding: 32px 24px;
        border: 1px solid var(--grey);
        background: var(--light-orange);
        .commas {
            img {
                margin-right: 2.5px;
                opacity: 0.5;
            }
        }

        blockquote {
            font-family: 'SourceSansPro-Regular';
            font-size: 20px;
            line-height: 28px;
            margin: 26px 0 24px 0;
        }

        .quote_author {
            h4 {
                font-family: 'SourceSansPro-SemiBold';
                font-size: 24px;
                line-height: 30px;
                color: var(--bodyText);
                margin-bottom: 4px;
            }
            span {
                font-family: 'SourceSansPro-Regular';
                font-size: 18px;
                line-height: 28px;
                color: var(--text2);
            }
        }
    }

    .ageBiasSection_hiring {
        margin-top: 54px;
        padding-right: 24px;

        &__list {
            margin-top: 24px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            row-gap: 18px;
            column-gap: 18px;
        }
    }
}

@media screen and (max-width: 1080px) {
    .ageBiasSection {
        margin: 64px auto;
        .ageBiasSection_container {
            flex-direction:  column;
            align-items: flex-start;
            .leftRail,
            .rightRail {
                width: 100%;
            }

            .ageBiasSection_main {
                .ageBiasSection_list {
                    &__item {
                        h3, p {
                            font-size: 20px;
                            line-height: 28px;
                        }
                    }
                }
            }
            .ageBiasSection_hiring {
                padding: 0;
                margin-bottom: 64px;
                &__list {
                    justify-content: center;
                    flex-wrap: wrap;
                    amp-ad {
                        max-width: 162px;
                        max-height: 156px;
                        min-width: 152px;
                        min-height: 146px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 568px) {
    .ageBiasSection {
        margin: 64px auto;
        .ageBiasSection_container {
            .ageBiasSection_main {
                .ageBiasSection_list {
                    &__item {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        p {
                            padding-left: 0;
                        }
                    }
                }
            }
            .ageBiasSection_hiring {
                .ageBiasSection_hiring__list {
                    // flex-direction: column
                    align-items: center;
                    justify-content: center;
                }
            }
            .quote {
                padding: 24px 16px;
            }
        }
    }
}
@media screen and (max-width: 320px) { // !TODO temporary leave

}