nav {
    background: var(--blue);
}

.navContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--blue);

    .navigationLeft,
    .navigationRight {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .navLink_item {
            font-family: SourceSansPro-SemiBold;
            font-size: 16px;
            line-height: 20px;
            color: var(--white);
            text-decoration: none;
            padding: 14px 16px;
            background: none;
            transition: background 0.2s;

            &:hover {
                background: var(--white);
                color: var(--blue);
            }
        }
        
        .active {
            background: var(--white-opacity-20);
        }
    }
    .navigationLeft {
        display: none;
    }
    .navigationRight {
        .navLink_item {
            height: 21px;
        }
    }
}

@media screen and (min-width: 1079.98px) {
    .navContainer {
        .navigationLeft {
            display: flex;
        }
    }
}

