.commonButton {
    padding: 12px 40px;
    height: 44px;
    font-family: SourceSansPro-Bold;
    color: var(--white);
    font-size: 16px;
    line-height: 20px;
    outline: none;
    border: none;
    cursor: pointer;
}

.orange {
    background-color: var(--orange);
    margin-right: 15px;
}

.careerServices {
    padding: 12px 0;
    width: 100%;
    background-color: var(--orange);
}