.advicesFilter {
    box-sizing: border-box;
    padding: 24px;
    max-width: 383px;
    background: var(--lightOrange);
    border: 1px solid var(--grey);

    &_header {
        font-family: SourceSansPro-SemiBold;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 24px;
    }

    &_inputs {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.advicesFilter_mobile {
    display: none;
}

@media screen and (max-width: 1080px) {
    .advicesFilter {
        display: none;
    }

    .advicesFilter_mobile {
        display: block;
    }
}