.stateJobInfo {
    &_content {
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin: 40px 0 100px 0;

        @media screen and (max-width: 1180px) {
            flex-direction: column;
            margin-bottom: 64px;
        }

        @media screen and (max-width: 490px) {
            margin-bottom: 35px;
        }

        .stateJobInfo_leftrail {
            width: 70%;
            flex-grow: 2;
            .jobByCategories {
                border-top: none;
            }

            @media screen and (max-width: 1180px) {
                width: 100%;
                .jobByCategories {
                    margin-bottom: 0;
                    .linksList  {
                        .linksList_container {
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .linksColumn {
                gap: 8px;
            }

            .stateJobInfo_header {
                font-size: 32px;
                font-family: SourceSansPro-SemiBold;
                color: var(--orange);
            }

            .stateJobInfo_subtitle {
                margin-top: 32px;
                h4 {
                    font-size: 24px;
                    font-family: SourceSansPro-SemiBold;
                    color: var(--orange);
                    margin-bottom: 16px;
                }
                p {
                    font-family: SourceSansPro-Regular;
                    font-size: 18px;
                    line-height: 27px;
                    color: var(--darkText);
                }
            }

            .stateJobInfo_cities {
                margin-top: 40px;

                &__header {
                    margin-bottom: 16px;

                    h3 {
                        font-size: 24px;
                        font-family: SourceSansPro-SemiBold;
                        color: var(--orange);
                        margin-bottom: 16px;
                    }
                    p {
                        font-family: SourceSansPro-Regular;
                        font-size: 18px;
                        line-height: 27px;
                        color: var(--darkText);
                    }
                }

                .citiesList {
                    display: flex;
                    flex-direction: column;
                    padding: 24px;
                    border: 1px solid #DDD;
                    border-radius: 4px;

                    &_container {
                        max-height: 342px;
                        flex-wrap: wrap;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        @media screen and (max-width: 490px) {
                            max-height: 810px;
                            flex-wrap: nowrap;
                        }

                        .commonLink {
                            margin-right: 391px;
                            @media screen and (max-width: 768px) {
                                margin-right: 240px;
                            }
                            @media screen and (max-width: 550px) {
                                margin-right: 180px;
                            }
                            @media screen and (max-width: 480px) {
                                margin-right: 0;
                            }
                        }
                    }
                    &_other {
                        margin-top: 32px;
                        font-family: SourceSansPro-Regular;
                        font-size: 18px;
                        line-height: 27px;
                    }
                }
            }

            .preloader {
                margin-top: 30px;
            }
        }

        .stateJobInfo_rightrail {
            flex-basis: 383px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 24px;

            @media screen and (max-width: 1180px) {
                gap: 40px;
            }

            .justForFun {
                ul {
                    list-style: none; 
                    padding-left: 16px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    font-family: SourceSansPro-Regular;
                    font-size: 18px;
                    line-height: 27px;

                    li::before {
                        content: "\2022";
                        color: var(--orange);
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                      }
                }
            }
            .justForFun.category {
                ul {
                    list-style: none; 
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    font-family: SourceSansPro-Regular;
                    font-size: 18px;
                    line-height: 27px;

                    li::before {
                        content: none;
                        color: none;
                        font-weight: none;
                        display: none;
                        width: 0;
                        margin-left: 0;
                      }
                }
            }
        }
    }
}