.employerReviewForm {
    &_header {
        &__text {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 27px;
            color: var(--bodyText);
            &:first-child {
                margin-bottom: 16px;
            }
        }
    }
    &_form {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .commonButton {
            width: 132px;
        }
      
        .button_wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            svg {
                margin-left: 20px;
            }
        }

        &__inputGroup {
            display: flex;
            flex-direction: column;
            gap: 16px;

            span {
                color: var(--red500);
            }

            .input-group {
                input {
                    margin-right: 0;
                    &::placeholder {
                        color: var(--text2)!important;
                        opacity: 0.5!important;
                    }
                    &:disabled {
                        appearance: none;
                        user-select: auto;
                        cursor: not-allowed;

                        padding: 10px 16px;
                        color: var(--text2)!important;
                        -webkit-text-fill-color: var(--text2)!important;
                    }
                }
            }

            .radios_list {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            &.company_rating {
                gap: 4px;

                .rate_container {
                    span {
                        color: var(--text2);
                        font-family: SourceSansPro-Regular;
                        font-size: 18px;
                        line-height: 28px;    
                    }
                }
            }

            .input-group {
                flex-basis: auto;
                p.error {
                    font-family: SourceSansPro-Regular;
                    font-size: 14px;
                    line-height: 24px;
                    margin-top: 4px;
                    color: var(--red500);
                }
            }

            .fieldsGroup {
                display: grid;
                grid-auto-flow: row dense;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: min-content min-content;
                gap: 16px;
                grid-template-areas:
                    "first_name last_name"
                    "email phone";
    
                .first_name {
                    grid-area: first_name;
                }
    
                .last_name {
                    grid-area: last_name;
                }
    
                .email {
                    grid-area: email;
                }
    
                .phone {
                    grid-area: phone;
                }
    
                .message {
                    grid-area: message;
                }

                input  {
                    margin-right: 0;
                }

                .react-tel-input {

                    .special-label {
                        display: none;
                    }
        
                    .custom-phone-input {
                        font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
                        font-size: 18px;
                        line-height: 28px;
                        border-radius: 4px;
                        padding: 10px 16px 10px 60px !important;
                        max-height: 48px;
                        width: 100%;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                    }
        
                    .custom-phone-button {
                        .selected-flag {
                            width: 50px;
                            padding: 0px 0 0 10px;
        
                            .arrow {
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-top: 7px solid var(--text2);
                                border-radius: 3px;
        
                                &.up {
                                    border-top: none;
                                    border-bottom: 7px solid var(--text2);
                                }
                            }
                        }
        
                        .custom-dropdown {
                            margin: 4px 0 4px -1px;
                            border-radius: 0;
        
                            .divider {
                                padding: 0;
                                margin: 0;
                                border-bottom: 1px solid var(--grey);
                            }
        
                            .country {
                                height: 48px;
        
                                font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
                                font-size: 16px;
                                line-height: 24px;
                                color: var(--bodyText);
        
                                &:hover {
                                    background-color: var(--light-grey2);
                                }
        
                                .flag {
                                    margin-top: 4px;
                                }
        
                                .dial-code {
                                    color: var(--text2);
                                }
                            }
                        }
                    }
                }
            }

            .inputGroup_group {
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 16px;

                .input-group {
                    width: 100%;
                    input {
                        margin-right: 0;
                    }
                }
            }

            p {
                font-family: SourceSansPro-SemiBold;
                font-size: 18px;
                line-height: 27px;
                color: var(--bodyText);
            }

            label {
                font-family: SourceSansPro-Regular;
                color: var(--bodyText);
                margin-bottom: 8px;
            }

            input {
                border-radius: 4px;
                border: 1px solid var(--grey-stroke, #CCC);
                padding: 14px 16px;
            }

            .txtCounter {
                top: 9.5em!important;
                color: var(--text2);
            }

            input[type="radio"]  {
                &+span {
                    padding-left: 40px;
                }
                &+span::before {
                    border-color: #CCC;
                }
                &:checked + span::before {
                    border-color: var(--orange);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .employerReviewForm {
        &_form {
            margin-top: 32px;
            gap: 32px;
        }
    }
}

@media screen and (max-width: 425px) {
    .employerReviewForm {
        &_form {
            gap: 24px;

            &__inputGroup {
                gap: 12px;

                .inputGroup_group {
                    flex-direction: column;
                    gap: 12px;
                }

                .fieldsGroup {
                    display: grid;
                    grid-auto-flow: column dense;
                    grid-template-columns: 100%;
                    grid-template-rows: 1fr 1fr 1fr 1fr;
                    gap: 12px;
                    grid-template-areas:
                        "first_name"
                        "last_name"
                        "email" 
                        "phone";
                }

                .checkbox {
                    margin-top: 12px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}