.contuctUsComponent {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &_description {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
}
