.linksList {

    .divider {
        border-bottom: 1px solid var(--grey);
    }

    .linksList_container {
        padding: 24px 0 20px 0;
        .linksList_title__link {
            text-decoration: none;
            color: var(--body-text);
        }

        .linksList_title__text {
            font-family: 'SourceSansPro-SemiBold';
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;
            color: var(--body-text);
        }

        .linksList_list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            a {
                margin-bottom: 4px;
            }
        }

        .linksColumn {
            display: flex;
            flex-direction: column;
            width: 219px;
            span {
                margin-bottom: 4px;
            }
        }
    }
}