.jobSeeker {
    margin: 0 auto;
    margin-bottom: 100px;
    
    &_container {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
    }
}
@media screen and (max-width: 1136px) {
    .jobSeeker {
        margin-bottom: 64px;
        width: 100%;
        &_container {
            flex-direction: column;
            column-gap: 0;
            row-gap: 16px;
        }
    }
}