.interviewPage {
    .interviewPage_content {
        margin: 40px auto 100px auto;
        display: flex;
        flex-direction: row;
        gap: 40px;

        @media screen and (max-width: 1150px) {
            margin-bottom: 64px;
            flex-direction: column;
        }

        .interviewPage_leftrail {
            width: 70%;
            @media screen and (max-width: 1150px) {
                width: 100%;
            }

            .interviewPage_header {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-bottom: 32px;

                @media screen and (max-width: 320px) {
                    margin-bottom: 24px;
                }

                h2 {
                    font-family: SourceSansPro-SemiBold;
                    color: var(--orange);
                    font-size: 32px;

                    @media screen and (max-width: 320px) {
                        font-size: 24px;
                    }
                }

                p {
                    font-size: 18px;
                    font-family: SourceSansPro-Regular;
                    line-height: 28px;
                    color: var(--bodyText);
                    padding-right: 130px;
                    @media screen and (max-width: 1150px) {
                        padding-right: 0px;
                    }
                }
            }

            .interviewPage_form {
                iframe {
                    width: 100%;
                    min-width: 320px;
                }
            }
        }
        .interviewPage_rightrail {
            width: 30%;

            @media screen and (max-width: 1150px) {
                width: 100%;
            }
        }
    }
}