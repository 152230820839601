.radio{
    display: block;
    cursor: pointer; 
    user-select:none;
    text-align: left;

    input{
      display: none;
        &+span{
            display: inline-block; 
            position: relative;
            padding-left: 32px;
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            color: var(--bodyText);

            &:before{
                content: '';
                display: block; 
                box-sizing: border-box;
                position: absolute;
                top: 0px;
                left: 0px;
                border-radius: 50%;
                margin-right: 10px;
                width: 20px;
                height: 20px;
                border: 2px solid var(--orange);
                background: #fff;
            }
            &:after{
            content: '';
                display: block; 
                width: 10px;
                height: 10px;
                background: var(--orange);
                position: absolute;
                border-radius: 50%;
                top: 5px;
                left: 5px;
                opacity: 0;
                transform: scale(0,0); 
                transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
            }
        }
        &:checked+span:after{
            opacity: 1;
            transform: scale(1,1);
        }
    }
}