.topBar {
    background-color: var(--white);
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 1080px) {
    .topBar {
        .topBar_logo {
            svg {
                width: 280px;
                
            }
        }
        .title-orange {
            font-size: 24px;
            line-height: 30px;
        }
    }
}
@media screen and (max-width: 568px) {
    .topBar {
        flex-direction: column;
        padding: 16px 0;
        .topBar_logo {
            svg {
                width: 287px;
            }
        }

        .title-orange {
            margin-top: 8px;
            line-height: 38px;
        }
    }
}