.recentlyReviewed {
    margin: 40px 0;
    &_title {
        margin-bottom: 12px!important;
    }
    .recentlyReviewed_columns {
        display: flex;
        border-radius: 4px;
        border: 1px solid #CCC;
        padding: 16px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}