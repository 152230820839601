.AFCertified {  
    display: grid;
    grid-template-columns: [first] 116px [second] auto;
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-template-areas:
      "logo title"
      "logo content";

    margin-bottom: 24px;
    gap: 12px 10px;

    .AFCertified_title {
        grid-area: title; 
        padding-top: 6px;
        h3 {
            margin-bottom: 0;
        }
    }
  
    .AFCertified_content {
        grid-area: content; 
        color: var(--bodyText);
        font-family: SourceSansPro-Regular;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 12px;
    }
    
    .AFCertified_logo { 
        align-self: start;
        grid-area: logo; 
        width: 116px;
        height: 116px;
    }  
    
    @media screen and (max-width: 425px) {
        display: grid;
        grid-template-columns: [first] auto [second] 90px;
        grid-template-rows: 0.5fr 1fr;
        grid-auto-flow: row;
        grid-template-areas:
          "title logo"
          "content content";
    
        margin-bottom: 24px;
        gap: 16px 40px;

        .AFCertified_logo { 
            width: 90px;
            height: 90px;
        }  

        .AFCertified_title {
            padding-right: 10px;
            h3 {
                line-height: 30px;
            }
        }
    }
}