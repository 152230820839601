.htmlRenderer {
    ul, ol {
        padding-left: 24px;
    }
    sup {
        font-size: 14px;
    }
    strong {
        font-family: SourceSansPro-SemiBold;
    }
    em {
        font-style: italic;
        white-space: break-spaces;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
    p {
        margin: 10px 0;
    }
}