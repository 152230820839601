.JESection {
    margin: 100px auto;

    .JESection_container {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
    }
}

@media screen and (max-width: 1080px) {
    .JESection {
        .JESection_container {
            width: 100%;
            flex-direction: column;
            row-gap: 16px;
            column-gap: 0px;
        }
    }
}