.companiesList {
    display: flex;
    gap: 8px;
    position: relative;

    border-radius: 4px;
    border: 1px solid #CCC;
    padding: 16px;
    transition: max-height 0.3s;

    &_company {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;

        span {
            font-size: 20px;
            font-family: SourceSansPro-SemiBold;
            color: var(--bodyText);
        }

        .companiesListItem {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    .firstColumn,
    .secondColumn {
        flex: 1;
    }

    &_showMore {
        margin: 0 auto;
        background: var(--white);
        padding-top: 24px;
        padding-bottom: 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &__btn {
            font-family: SourceSansPro-SemiBold;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: var(--orange);
            padding: 10px 25px;
            margin: 0 auto;
            max-width: 250px;
            border-radius: 4px;
            border: 2px solid #FD8848;

            @media screen and (max-width: 375px) {
                padding: 10px;
            }
        }

        @media screen and (min-width: 376px) {
            display: none;
        }
    }

    @media screen and (max-width: 375px) {
        flex-direction: column; 
        max-height: 710px;
        overflow: hidden;

        &.open {
            max-height: fit-content;
        }
    }
}