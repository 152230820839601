.jobsStates {
    border-top: 1px solid #ccc;
    .linksList {
        .contentWrapper {
            padding: 0;
            .linksList_container  {
                
                padding-bottom: 40px;
                padding-top: 40px;
                .linksList_title__text {
                    color: var(--orange);
                    font-size: 24px;
                    margin-bottom: 24px;
                }
            }
        }
    }
}
.jobsStates.category{
    border-top: none;
    .linksList {
        .contentWrapper { 
            .linksList_container  {
                padding-top: 20px;
                .linksList_title__text {
                    color: var(--orange);
                    font-size: 24px;
                    margin-bottom: 24px;
                }
            }
        }
    }
}