:root {
    // Neutral
    --white: #ffffff;
    --black: #000;
    --bodyText: #292526;
    --darkText: #1D1D1D;
    --grey: #CCCCCC;
    --light-grey: #F5F6F7;
    --light-grey2: #F2F2F2;
    --dark-grey: #6A6A6A;
    --text2: #706F6F;
    --text2-opacity-90: rgba(112,111,111, 0.9);
    --white-opacity-20: rgba(255, 255, 255, 0.2);
    --grey2: #7C7C7D;

    // Primary
    --orange: #FD8848;
    --lightOrange: #FFFCFB;
    --blue: #344689;
    --greyDisabled: #AFAFB4;
    --red300: #FDE9DF;
    --red500: #CC0F00;

    // Background
    --light-orange: #FFFCFB;
    --light-blue: #F2F7FE;
    --light-blue2: #F7FBFF;
    --medium-blue: #C1D8FB;
    --light-beige:#FFF5F2;
    --light-gray3: #F8F7F7;
    --light-gray4: #F5F5F5;
}