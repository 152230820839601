.customDropdown {
    
    position: relative;
    width: inherit;

    .dropdownHeader {
        font-size: 18px;
        font-family: SourceSansPro-Regular;
        line-height: 28px;
        color: var(--bodyText);
        padding: 8px 12px;
        border: 1px solid var(--grey);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .selectedCategory {
            font-family: SourceSansPro-Bold;
        }

        .dropdownHeader_icon {
            transform: rotate(0deg);
            transition: all 0.3s;
            &.active {
                transform: rotate(180deg);
            }
        }
    }

    .dropdownOptions {
        position: absolute;
        background-color: var(--white);
        top: 41px;
        left: 0;
        width: 99.8%;
        margin-top: 8px;
        border: 1px solid var(--grey);
        list-style: none;
        font-size: 18px;
        font-family: SourceSansPro-Regular;
        line-height: 28px;
        color: var(--bodyText);
        & li {
            padding: 8px 12px;
            cursor: pointer;
            &:hover {
                background-color: rgba($color: #ccc, $alpha: 0.3);
            }
        }
    }

    @media screen and (max-width: 622px) {
        .dropdownOptions {
            width: 99.7%;
        }
    }
    @media screen and (max-width: 426px) {
        .dropdownOptions {
            width: 99.6%;
        }
    }
    @media screen and (max-width: 320px) {
        .dropdownOptions {
            width: 99.5%;
        }
    }
}