.faqComponent {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &_description {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &_info {
            display: flex;
            flex-direction: column;
            gap: 5px;

            &_radio {
                display: flex;
                gap: 24px;

                @media screen and (max-width: 380px) {
                    flex-direction: column;
                }
            }
        }
    }

    &_accordionBlock {

        .accordion {
            display: flex;
            flex-direction: column;
            gap: 24px;

            @media screen and (max-width: 380px) {
                gap: 16px;
            }

            .accordion__button {
                background-color: var(--white);
                border: 1px solid var(--grey);
                padding: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                font-family: 'SourceSansPro-SemiBold', Arial, Helvetica, sans-serif;
                font-size: 20px;
                line-height: 28px;

                &::after {
                    display: inline-block;
                    content: '+';
                    font-size: 24px;
                    margin-right: 12px;
                }

                &[aria-expanded='true']::after,
                &[aria-selected='true']::after {
                    content: '—';
                    font-size: 22px;
                }

                @media screen and (max-width: 380px) {
                    font-size: 18px;
                }
            }

            .accordion__panel {
                animation: fadein 0.35s ease-in;
    
                background-color: var(--white);
                padding: 16px;
                border: 1px solid var(--grey);
                border-top: 0;

                font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
                font-size: 18px;
                line-height: 28px;
                color: var(--text2);
                
                p {
                    b {
                        font-weight: bold;
                    }
                }

                div {
                    p {
                        margin-bottom: 11px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    ul {
                        margin-left: 30px;
                        margin-bottom: 11px;
                    }
                }
            }

            [hidden] {
                display: none;
            }
        }
    }
}