.advicesList {
    &_header {
        font-size: 32px;
        font-family: SourceSansPro-SemiBold;
        color: var(--orange);
        margin: 40px 0;
    }
    &_content {
        display: flex;
        flex-direction: row;
        margin-bottom: 100px;

        @media screen and (max-width: 768px) {
            margin-bottom: 64px;
        }

        .advicesList_items {
            width: 70%;
            border-top: 1px solid var(--grey);
            flex-grow: 2;
            margin-right: 40px;

            .preloader {
                margin-top: 30px;
            }

            .pagination{ 
                margin-bottom: 0;
            }
        }

        .advicesList_filters  {
            flex-basis: 383px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .careerServicesLink {
                margin-bottom: 100px;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .advicesList {
        &_content {
            flex-direction: column-reverse;

            .advicesList_items {
                width: 100%;
                margin-top: 16px;
            }

            .advicesList_filters {
                flex-basis: auto;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .advicesList {
        &_header {
            margin: 32px 0;
        }
    }
}

@media screen and (max-width: 568px) {
    .advicesList {
        &_content {
            .advicesList_items {
                border: none;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .advicesList {
        &_header {
            font-size: 24px;
            line-height: 30px;
        }
    }
}