.feedbackSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    &_certifiedEmployer {

        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        span {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 27px;
            color: var(--bodyText);
        }
        img {
            margin-right: 12px;
        }
        a  {
            margin-left: 4px;
        }
    }

    &_feedback {
        h4 {
            font-family: SourceSansPro-SemiBold;
            font-size: 18px;
            line-height: 27px;
            color: var(--bodyText);
            margin-bottom: 8px;
        }

        p {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 27px;
            color: var(--bodyText);

            a {
                margin: 0 4px;
            }
        }
    }
    &_testimonials {
        h4,
        blockquote > footer {
            font-family: SourceSansPro-SemiBold;
            font-size: 18px;
            line-height: 27px;
            color: var(--bodyText);
            margin-bottom: 8px;
        }

        blockquote {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 27px;
            color: var(--bodyText);

            footer {
                margin-bottom: 0;
                margin-top: 8px;
            }
        }
    }
}