.reviewsPage {
   .reviewsPage_content {
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin: 32px auto;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        h2, h3 {
            color: var(--orange);
            font-size: 24px;
            font-family: SourceSansPro-SemiBold;
            margin-bottom: 8px;
        }
        
        .reviewsPage_leftRail {
            width: 70%;
            flex-grow: 2;

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            .reviewsPage_header {
                margin-bottom: 24px;
                h2 {
                    font-size: 32px;
                    margin-bottom: 12px;
                }

                p {
                    color: var(--bodyText);
                    font-family: SourceSansPro-Regular;
                    font-size: 18px;
                    line-height: 27px;

                    a {
                        color: var(--blue);
                        &:hover {
                            color: var(--orange);
                            @media screen and (max-width: 1024px) {
                                color: var(--blue);
                            }
                        }
                    }
                }
            }
        }
        .reviewsPage_rightRail {
            flex-basis: 383px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .reviewsPage_rightRail__header {
                margin-bottom: 16px;

                p {
                    color: var(--bodyText);
                    font-family: SourceSansPro-Regular;
                    font-size: 18px;
                    line-height: 27px;
                }

            }
            .reviewsPage_rightRail__filters {
                .careerServicesLink {
                    align-items: flex-start;

                    span {
                        font-family: SourceSansPro-Regular;
                        font-size: 18px;
                        color: var(--bodyText);
                        margin-bottom: 12px;
                    }
                }
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                flex-basis: auto;
                margin-top: 40px;

                .reviewsPage_rightRail__header {
                    margin-bottom: 24px;
                }
                .reviewsPage_rightRail__filters {
                    .reviewsFilters {
                        flex-direction: row;

                        .companiesRatingFilter {
                            flex: 1;
                            margin-bottom: 0;
                        }
                        .companiesAlphabeticalFilter {
                            flex: 1;
                        }
                    }
                    .careerServicesLink {
                        margin-top: 24px;
                        margin-bottom: 0;
                    }
                }
            }

            @media screen and (max-width: 510px) {
                margin-top: 32px;

                .reviewsPage_rightRail__header {
                    margin-bottom: 24px;
                }
                .reviewsPage_rightRail__filters {
                    .reviewsFilters {
                        flex-direction: column;

                        .companiesRatingFilter {
                            margin-bottom: 32px;
                        }
                    }
                }
            }
        }
   } 
}