.mobileFilters {
    display: none;
}
@media screen and (max-width: 768px) {
    .mobileFilters {
        display: flex;
        flex-direction: row;
        width: 185px;
        gap: 12px;
        margin-bottom: 16px;

        .filterButton {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border: 2px solid #F5F6F7;
            border-radius: 4px;
            outline: none;
            background: var(--light-grey);
            padding: 2px 12px;

            font-family: 'SourceSansPro-Regular';
            font-size: 18px;
            line-height: 27px;
            color: var(--grey2);

            &.active {
                border-color: #FD8848;
                background: #DBE0E4;
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .letterFilter,
        .ratingFilter {
            position: relative;

            &_container {
                position: absolute;
                top: 39px;
                left: 0;
                z-index: 999;
                background-color: #fff;
                height: auto;
                padding: 12px;
                border: 1px solid #B3D9EA;
                border-radius: 4px;
                
                box-shadow: 0px 2px 6px 0px rgba(18, 18, 19, 0.10);
            }
        }

        .letterFilter {
            &_container {
                width: 390px;
                @media screen and (max-width: 420px) {
                    width: 273px;
                }
            }
        }
        .ratingFilter {
            &_container {
                width: auto;
            }
        }
    }
}