.reviewCompanyCard {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .company_rating {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &-rating {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .customStars {
                display: flex;
                flex-direction: row;
                gap: 6px;
            }

            span {
                font-family: SourceSansPro-Regular;
                font-size: 18px;
                line-height: 18px;
                color: var(--bodyText);
            }
        }

        .company_add-review {
            padding: 8px 0;

            &__button {
                color: var(--white);
                padding: 8px 16px;
                border-radius: 4px;
                background-color: var(--orange);
                font-family: SourceSansPro-Bold;
                font-size: 18px;
                line-height: 27px;
                cursor: pointer;
            }
        }
    }

    &_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        &__info {
            display: flex;
            flex-direction: row;
            gap: 28px;

            .company_avatar {
                height: 94px;
                width: 100px;
                img {
                    height: 94px;
                    width: 100px;
                }
            }

            .company_name {
                &__title {
                    margin-bottom: 8px;
                    a {
                        font-family: SourceSansPro-SemiBold;
                        font-size: 24px;
                        line-height: 24px;
                        color: var(--blue);
                        margin-bottom: 8px;
                    }
                }

                &__link {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                }

                .links_list {
                    display: flex;
                    flex-direction: column;   
                }
            }
        }
        &__rating {
            display: flex;
            flex-direction: row;
            gap: 48px;

            .company_agefriendly {
                height: 60px;
                width: 60px;
            }
        }
    }
    &_body {
        margin-top: -10px;
        .company_rating {
            display: none;
        }
        .company_description {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 28px;
            color: var(--bodyText);

            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        .showBtn {
            text-decoration: underline;
            color: var(--blue);
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 28px;
            cursor: pointer;
        }
    }
    &_reviews {
        h4 {
            font-family: SourceSansPro-SemiBold;
            color: var(--orange);
            font-size: 20px;
            margin-top: 16px;
            padding-bottom: 12px;
            
        }
        .reviewItem {
            border-top: 1px solid #ccc;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 60px;
            padding: 16px 8px;

            &__text {
                color: var(--bodyText);
                font-size: 18px;
                line-height: 27px;

                h5 {
                    font-family: SourceSansPro-SemiBold;
                    margin-bottom: 8px;
                }

                p {
                    font-family: SourceSansPro-Regular;
                }
                .replyReview {
                    margin-top: 16px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 16px;
                    padding-left: 32px;

                    &_content {
                        &__header {
                            margin-bottom: 4px;
                            line-height: 27px;
                        }
                    }
                }
            }

            &__rating {
                display: flex;
                flex-direction: column;
                gap: 8px;

                &-rating {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
                
                span {
                    font-family: SourceSansPro-Regular;
                    font-size: 18px;
                    line-height: 18px;
                    color: var(--bodyText);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .reviewCompanyCard {
        &_header {
            &__info {
                gap: 20px;
            }

            &__rating {
                gap: 36px;
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .reviewCompanyCard {
        padding: 12px;
        &_header {
            &__info {
                gap: 14px;
                .company_name {
                    &__title {
                        margin-bottom: 4px;
                        a {
                            font-size: 18px;
                        }
                    }
                }
                .company_avatar {
                    height: 40px;
                    width: 40px;
                    img {
                        height: 40px;
                        width: 40px;
                    }
                }
            }

            &__rating {
                .company_rating {
                    display: none;
                }
                .company_agefriendly {
                    height: 45px;
                    width: 45px;
                }
            }
        }
        &_body {
            margin-top: -14px;
            .company_rating {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
        &_reviews  {
            .reviewItem {
                flex-direction: column;
                gap: 12px;

                &__rating {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                }
                &__text {
                    .replyReview {
                        margin-top: 12px;
                        padding-left: 24px;
                    }
                }
                .socials_link {
                    margin-top: 0;
                }
            }
        }
    }
}