.rating_container {
    display: flex;
    flex-direction: column;

    .rating_label {
        font-family: SourceSansPro-Regular;
        color: var(--bodyText);
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
        margin-top: 16px;

        span {
            color: var(--red500)!important;
        }
    }

    .rating {
        display: flex;
        font-size: 24px;
    }
    
    .star {
        cursor: pointer;
        margin-right: 5px;
        width: 20px;
        height: 20px;
    }
    
    .star > svg path {
        transition: fill 0.3s;
    }
    
    .star.active > svg path {
        fill: #FD8848;
    }
    
    .error {
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
        color: var(--red500);
    }
}

