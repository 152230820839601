.inTheNewsComponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;

    &_list{
        width: 100%;
        &:first-child {
            border-top: 1px solid var(--grey);
        }

        .pagination {
            padding: 0;
        }
    }

    &_contentBlock {
        display: flex;
        flex-direction: column;
        align-items: center;

        .newsItem {
            display: grid;
            grid-template-columns: min-content 1fr;
            grid-template-rows: min-content;
            gap: 12px 16px;
            grid-template-areas:
                "image headingLink"
                "image content";

            .image {
                grid-area: image;
            }

            .headingLink {
                grid-area: headingLink;
            }

            .content {
                grid-area: content;
                align-self: flex-start;
            }

            @media screen and (max-width: 380px) {
                grid-template-areas:
                    "image headingLink"
                    "content content";
            }

            padding: 24px 0px;
            border-bottom: 1px solid var(--grey);

            img {
                width: 112px;
                height: 112px;
                object-fit: cover;

                @media screen and (max-width: 768px) {
                    width: 96px;
                    height: 96px;
                }

                @media screen and (max-width: 380px) {
                    width: 56px;
                    height: 56px;
                }
            }

            a {
                font-size: 20px;
                line-height: 28px;
                font-family: 'SourceSansPro-Regular';
                color: var(--blue);
            }

            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

    }
}