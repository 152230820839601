.backButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: none;
    font-family: SourceSansPro-SemiBold;
    color: var(--blue);
    font-size: 20px;
    line-height: 28px;
    outline: none;
    border: none;
    cursor: pointer;

    @media screen and (max-width: 580px) {
        font-size: 18px;
    }
}