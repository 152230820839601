.paginationButtons  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 670px) {
        span {
            display: none;
        }
    }
}