.mostPoular {
    border: 1px solid var(--orange);
    padding: 6px 12px;
    color: var(--orange);
    font-family: SourceSansPro-SemiBold;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 568px) {
        margin-bottom: 12px;
    }
}

.priceLabel {
    padding: 4px 8px;
    color: var(--blue);
    background: var(--light-blue);
    font-family: SourceSansPro-SemiBold;
    font-size: 20px;
    line-height: 28px;
}

.topicLabel {
    color: var(--blue);
    font-size: 16px;
    line-height: 24px;
}

.dateLabel {
    position: relative;
    width: 60px;
    height: 48px;
    background: var(--medium-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    &_date {
        font-family: SourceSansPro-SemiBold;
        font-size: 16px;
        color: var(--blue);
    }

    &::before,
    &::after{
        content: '';
        position: absolute;
        z-index: 20;
        height: 16px;
        width: 5px;
        background: var(--medium-blue);
        border: 2px solid var(--white);
        border-radius: 5px;
        top: -8px;
        left: 11px;
    }
    &::after {
        left: 40px;
    }
}

.webinarsTitle {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content;
    gap: 0 16px;
    margin-bottom: 8px;
    grid-template-areas:
        "date time"
        "date title";

    .date {
        grid-area: date;
    }

    .time {
        grid-area: time;
    }

    .title {
        grid-area: title;
        align-self: flex-start;
    }

    @media screen and (max-width: 380px) {
        gap: 12px 16px;
        grid-template-areas:
            "date time"
            "title title";

        .time {
            align-self: center;
        }
    }
}