.companiesSearchInput {
    .companiesSearchInput_wrapper {
        position: relative;
        span {
            color: var(--red500);
        }

        .rbt {
            margin-top: 8px;

            div {
                .rbt-input-hint {
                    display: none;
                }
            }
        }

        input {   
            width: 100%;
            border-radius: 4px;
            border: 1px solid #CCC;
            padding: 8px 12px;
            padding-right: 45px;
            outline: none;
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 27px;
            color: var(--text2);

            &::placeholder {
                font-family: SourceSansPro-Regular;
                font-size: 18px;
                color: var(--text2);
                line-height: 27px;
            }
        }

        .error {
            position: absolute;
            bottom: -22px;
            left: 0;
            color: red;
            font-family: SourceSansPro-Regular;
            font-size: 16px;
            background: rgba(255, 255, 255, 0.5);
        }

        .custom-menu-class {
            background: #fff;
            display: flex!important;
            flex-direction: column;
            border-radius: 4px;
            border: 1px solid #CCC;

            position: absolute;
            inset: 0px auto auto 0px;
            display: block;
            max-height: 300px;
            overflow: auto;
            transform: translate3d(0px, 48.5px, 0px);
            width: 100%;
            box-shadow: 0 0 12px 0 rgba(0,0,0,0.07);
            z-index: 10;

            &.hideMenu {
                display: none!important;
            }

            .custom-menu-item,
            .dropdown-item.disabled {
                padding: 10px 20px;
                text-decoration: none;
                cursor: pointer;
                font-family: SourceSansPro-Regular;
                font-size: 16px;
                overflow: visible;

                .searchLink {
                    text-decoration: none;
                    color: var(--text2);
                }

                &:hover {
                    background: var(--medium-blue);
                }
            }
        }
    }

    .unknownCompany {
        margin-top: 40px;
        max-width: 739px;
        p {
            font-family: SourceSansPro-Semibold;
            margin-bottom: 16px;
        }
        .input-group input::placeholder {
            color: #706F6F;
            opacity: 0.4;
        }

        @media screen and (max-width: 768px) {
            margin-top: 32px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 24px;
            p {
                margin-bottom: 12px;
            }
        }


    }
}