.searchSection {
    width: 100%;
    min-height: 478px;
    display: flex;
    position: relative;
    z-index: 1;

    &_header {
        font-family: SourceSansPro-Bold;
        font-size: 40px;
        line-height: 50px;
        color: var(--white);
        margin-bottom: 24px;
    }
    .searchSection_main {
        margin: 39px 0 292px 0;
    }

    .searchSection_inputs {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;
        .input-group,
        .autocompleteInput {
            margin-right: 16px;
            @media screen and (max-width: 1153px) and (min-width: 1080px) {
                margin-bottom: 16px;
            }
            @media screen and (max-width: 753px) {
                margin-bottom: 16px;
            }
        }
        .input-group.keyword_input {
            label {
                font-family: SourceSansPro-SemiBold!important;
                color: var(--white)!important;
                margin-bottom: 0!important;
            }
            input {
                border: none!important;
            }
        }
    }

    .searchSection_container {
        display: flex;
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        padding: 0 40px 0 44px;
        position: relative;
    }
    .searchFields_bg,
    .searchSeal_bg {
        position: absolute;
        top: 0;
        bottom: 0;
    }
    .searchFields_bg {
        width: 72%;
        left: 0;
        &.searchFields_bg-image {
            background-image: url(../../../../assets/images/searchBGImage.webp);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top right;

            @media screen and (min-width: 1330px) {
                background-size: 100%;
            }

            @media screen and (min-width: 1550px) {
                background-position: center right;
                border: none;
            }
            
        }
    }
    .searchSeal_bg {
        width: 28%;
        right: 0;
        background-color: var(--medium-blue);
    }
    .searchFields {
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 2;
        width: 100%;
        &__container {
            margin-top: 60px;
            width: 100%;
            padding-bottom: 23px;
            position: relative;
            z-index: 1;
        }
        .search-container {
            margin-top: 24px;
            max-width: 768px;
            width: 100%;
            .select-container {
                flex-basis: 211px;
            }
        }
    }
    .searchSeal {
        flex-basis: 553px;
        background-color: var(--medium-blue);
        &__content {
            margin-top: 12px;
        }
    }    
}

@media screen and (max-width: 1080px) {
    .searchSection {

        .searchFields_bg,
        .searchSeal_bg {
            display: none;
        }
        .searchSection_container {
            flex-direction: column;
            padding: 0;
            max-width: 1080px;
            .searchFields {
                &.searchFields_bg-image {
                    background-image: url(../../../../assets/images/searchBGImage.webp);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                }

                .searchSection_main {
                    padding: 0 24px;

                    .searchSection_header {
                        font-size: 32px;
                        line-height: 40px;
                    }

                    // .searchSection_inputs {

                    // }
                }
            }
            .searchSeal {
                flex-basis: auto;
            }
        }
    }
}

@media screen and (max-width: 610px) {
    .searchSection {
        .searchSection_container {
            .searchFields {
                &.searchFields_bg-image {
                    background-position: center center;
                }
                .searchSection_main {
                    padding: 0 8px;
                    margin-bottom: 150px;

                    .searchSection_header {
                        font-size: 24px;
                        line-height: 30px;
                    }

                    .searchSection_inputs {
                        flex-direction: column;
                        justify-content: center;
                        align-items: stretch;
                        .input-group,
                        .autocompleteInput {
                            flex-basis: auto;
                            margin: 0;
                            margin-bottom: 16px;
                            input {
                                margin: 0;
                            }
                        }

                        .autocompleteInput {
                            input {
                                width: 100%;
                            }
                        }
                        .orange {
                            margin: 0;
                        }
                    }
                }
            }
            .searchSeal {
                flex-basis: auto;
            }
        }
    }
}