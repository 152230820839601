.findJobBlock {
    padding: 24px;
    width: calc(380px - 48px);
    height: 347px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--white);
    box-shadow: 0px 10px 55px rgba(0, 0, 0, 0.05);
    position: relative;

    .findJobBlock_logo {
        img {
            height: 86px;
        }
    }

    .findJobBlock_header {
        margin-top: 18px;
        margin-bottom: 16px;

        font-family: SourceSansPro-SemiBold;
        font-size: 24px;
        line-height: 30px;
        color: var(--bodyText);
    }

    .findJobBlock_description {
        font-family: SourceSansPro-Regular;
        font-size: 18px;
        line-height: 27px;
        color: var(--bodyText);
        opacity: 0.7;
        
    }
    .findJobBlock_link {
        position: absolute;
        bottom: 24px;
        left: 24px;
    }
}

@media screen and (max-width: 1080px) {
    .findJobBlock {
        width: 96%;
        padding: 16px;
        height: 280px;
        .findJobBlock_link {
            bottom: 16px;
            left: 16px;
        }
    }
}
@media screen and (max-width: 568px) {
    .findJobBlock {
        width: 94%;
        height: auto;
        .findJobBlock_description {
            padding-bottom: 34px;
        }
    }
}