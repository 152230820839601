.contuctUsForm {

    .formError {
        margin-bottom: 20px;
    }

    form {

        display: flex;
        flex-direction: column;
        gap: 16px;

        button {
            max-width: 173px;

            @media screen and (max-width: 380px) {
                max-width: 100%;
            }
        }

        .fieldsContainer {

            display: grid;
            grid-auto-flow: row dense;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: min-content min-content min-content min-content;
            gap: 12px 16px;
            grid-template-areas:
                "first_name last_name"
                "email phone"
                "message message";

            .first_name {
                grid-area: first_name;
            }

            .last_name {
                grid-area: last_name;
            }

            .email {
                grid-area: email;
            }

            .phone {
                grid-area: phone;
            }

            .message {
                grid-area: message;
            }

            @media screen and (max-width: 672px) {
                grid-template-columns: 1fr; 
                grid-template-areas:
                    "first_name"
                    "last_name"
                    "email"
                    "phone"
                    "message";
            }

        .input-group.commonFormInput {
            width: 100%;
            min-width: 304px;

            label {
                font-family: SourceSansPro-Regular;
                color: var(--bodyText);
                margin-bottom: 8px;
                span {
                    color: var(--red500);
                }
            }

            input {
                margin-right: 0;
                padding: 10px 16px;
                border: 1px solid var(--grey);
                border-radius: 4px;

                box-sizing: border-box;
            }

            input {
                height: 48px;
            }

            p.error {
                font-family: SourceSansPro-Regular;
                font-size: 16px;
                line-height: 24px;
                margin-top: 4px;
                color: var(--red500);
            }
        }

        .react-tel-input {

            .special-label {
                display: none;
            }

            .custom-phone-input {
                font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
                font-size: 18px;
                line-height: 28px;

                padding: 10px 16px 10px 60px !important;

                max-height: 48px;
                width: 100%;

                -webkit-box-shadow: none;
                box-shadow: none;
            }

            .custom-phone-button {
                .selected-flag {
                    width: 50px;
                    padding: 0px 0 0 10px;

                    .arrow {
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-top: 7px solid var(--text2);
                        border-radius: 3px;

                        &.up {
                            border-top: none;
                            border-bottom: 7px solid var(--text2);
                        }
                    }
                }

                .custom-dropdown {
                    margin: 4px 0 4px -1px;
                    border-radius: 0;

                    .divider {
                        padding: 0;
                        margin: 0;
                        border-bottom: 1px solid var(--grey);
                    }

                    .country {
                        height: 48px;

                        font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--bodyText);

                        &:hover {
                            background-color: var(--light-grey2);
                        }

                        .flag {
                            margin-top: 4px;
                        }

                        .dial-code {
                            color: var(--text2);
                        }
                    }
                }
            }
        }
    }
}
}