.moreResources {
    .moreResources_list {
        font-family: SourceSansPro-Regular;
        font-size: 18px;
        line-height: 27px;
        color: var(--darkText);
        display: flex;
        flex-direction: column;
        gap: 8px;

        .moreResources_item {
            a {
                color: var(--blue);
                white-space: break-spaces;
                margin-left: 5px;
            }
        }
    }
}