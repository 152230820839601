.successPopup {
    display: flex;
    flex-direction: column;
    background: var(--white);
    max-width: 500px;
    position: relative;

    .closeShape {
        position: absolute;
        z-index: 2;
        top: 24px;
        right: 24px;

        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

    }
    &_header {
        padding: 32px 40px;
        background-color: var(--light-beige);
        p {
            font-family: SourceSansPro-SemiBold;
            font-size: 32px;
            line-height: 40px;
            color: var(--bodyText);
        }
    }
    &_content {
        padding: 40px 24px 40px 40px;
        p {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 28px;
            color: var(--bodyText);
            margin-bottom: 24px;
        }

        .successPopup_button {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
}