.formError {
    padding: 16px;
    box-sizing: border-box;
    background-color: var(--red300);

    p {
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        line-height: 24px;
        color: var(--red500);
    }
}