.notFoundPage {
    height: 824px;
    width: 100%;
    position: relative;

    &_content {
        padding-top: 176px;
        padding-bottom: 266px;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        gap: 40px;

        .notFoundPage_image {
            margin: 0 auto;
        }

        .notFoundPage_text {
            font-family: SourceSansPro-Regular;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            p {
                color: var(--bodyText);
                margin-bottom: 4px;
            }
            span {
                color: var(--text2);
            }
            .notFoundPage_button {
                margin-top: 16px;
                outline: none;
                border: none;
                background-color: var(--orange);
                padding: 12px 40px;
                color: var(--white);
                font-family: SourceSansPro-SemiBold;
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
            }
        }

        @media screen and (max-width: 768px) {
            padding: 33px 16px;
        }

        @media screen and (max-width: 500px) {
            .notFoundPage_image {
                img {
                    width: 200px;
                }
            }

            .notFoundPage_text {
                font-size: 16px;
            }
        }
    }
}