.infoPages_content {
    padding: 40px 0 100px 0;

    .title-orange {
        padding-bottom: 16px;
    }

    @media screen and (max-width: 1080px) {
        padding: 32px 0 64px 0;
    }

    @media screen and (max-width: 480px) {
        padding: 24px 0 64px 0;
    }
}