.companiesRatingFilter {
    margin-bottom: 32px;

    h4 {
        color: var(--orange);
        font-size: 20px;
        font-family: SourceSansPro-SemiBold;
        margin-bottom: 12px;
    }
    .companiesRatingFilter_radios {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}