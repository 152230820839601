.сommonListItem {
    padding: 24px 16px;
    border-bottom: 1px solid var(--grey);
    font-family: SourceSansPro-Regular;
    box-sizing: border-box;

    &.webinars {
        padding: 24px 0;
    }

    &_header {
        &__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 4px;

            & div {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        &__date {
            font-family: SourceSansPro-Regular;
            font-size: 16px;
            line-height: 24px;
            color: var(--blue);
        }

        &__text {
            font-family: SourceSansPro-SemiBold;
            font-size: 24px;
            line-height: 30px;
            color: var(--bodyText);
            margin-right: 12px;
        }
    }

    &_content {
        &__text {
            color: var(--text2);
            font-size: 18px;
            line-height: 28px;
        }
    }

    &.careerService {
        padding: 24px 0;
        .сommonListItem_header {
            margin-bottom: 12px;
        }
    }

    &.hover {
        &:hover {
            background-color: var(--lightOrange);
            & .сommonListItem_header__text {
                color: var(--orange);
            }
        }
    }
}

.listItemLink {
    text-decoration: none;
}

@media screen and (max-width: 568px) {
    .сommonListItem {
        border: 1px solid var(--grey);
        margin-bottom: 16px;
        &.webinars {
            border: none;
            border-bottom: 1px solid var(--grey);
            margin-bottom: 0;
        }
        &.careerService {
            .сommonListItem_header__wrapper {
                flex-direction: column-reverse;
                align-items: flex-start;
    
                div {
                    width: 100%;
                    justify-content: space-between;
                }
            }
        }
    }
    .сommonListItem.careerService {
        padding: 16px;
    }


}
@media screen and (max-width: 380px) {
    .сommonListItem {
        &_header {
            &__text {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}