.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    padding: 30px 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
    overflow: auto;
      
    .modal {
    margin: 50px auto;
    background-color: #fff;
    width: 800px;

        .modal-content--header {
            padding: 32px 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            background-color: var(--light-beige);

            h4 {
                font-family: SourceSansPro-SemiBold;
                font-size: 32px;
                color: var(--bodyText);
            }

            span {
                margin-top: 10px;
                cursor: pointer;
            }
        }
        .modal-content--body {
            background-color: var(--white);
            padding: 24px 40px;

            @media screen and (max-width: 425px) {
                padding: 16px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .modal {
            width: 672px;
        }
    }
    @media screen and (max-width: 425px) {
        padding: 0;
        .modal {
            width: 304px;

            .modal-content--header {
                padding: 16px;
                h4 {
                    font-size: 20px;
                    line-height: 28px;
                }
                span {
                    margin: 0;
                }
            }
        }
    }
}
