.contentWrapper {
    margin: 0 auto;
    padding: 0 44px;
    max-width: 1280px;

    @media screen and (max-width: 1080px) {
        max-width: 1080px;
        padding: 0 24px;
    }
    @media screen and (max-width: 568px) {
        max-width: 568px;
        padding: 0 8px;
    }
}