.careerSelection {
    margin: 24px 0 12px 0;
    &_title {
        margin-bottom: 12px;
        p {
            font-family: SourceSansPro-SemiBold;
            font-size: 18px;
            line-height: 28px;
        }
        span {
            font-family: SourceSansPro-Regular;
            font-size: 16px;
            line-height: 24px;
            margin-top: 4px;
            color: var(--red500);
        }
    }

    .checkbox {
        margin-bottom: 18px;
    }
}