.reviewCompaniesList {
    &_container {
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin: 32px auto;
        margin-bottom: 100px;

        .reviewCompaniesList_leftRail {
            width: 70%;
            flex-grow: 2;
            display: flex;
            flex-direction: column;

            .noResults {
                margin: 20px auto;
                font-family: SourceSansPro-Semibold;
                font-size: 24px;
                color: var(--bodyText);
                text-align: center;
            }

            .reviewCompaniesList_content {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-bottom: 40px;

                .pagination {
                    padding: 0;
                    margin: 0;
                }
            }

            .reviewsSearch {
                margin-bottom: 16px;
            }
        }

        .reviewCompaniesList_rightRail {
            flex-basis: 383px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 768px) {
    .reviewCompaniesList {
        &_container {
            display: flex;
            flex-direction: column-reverse;
            gap: 16px;
            margin: 24px auto;
            margin-bottom: 64px;

            .reviewCompaniesList_leftRail {
                width: 100%;
                flex-grow: 1;

                .reviewCompaniesList_content {
    
                    .pagination {
                        margin-top: 24px;
                    }
                }
            }    

            .reviewCompaniesList_rightRail {
                display: none; // temporary hide
            }
        }
    }
}