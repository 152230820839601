.aboutUsSection {
    &_content {
        display: flex;
        flex-direction: row;
        margin: 40px 0;
        gap: 40px;

        .aboutUsSection_itemsBlock {
            width: 70%;
            flex-grow: 2;

            h2 {
                font-size: 32px;
                font-family: 'SourceSansPro-SemiBold';
                color: var(--orange);
    
                @media screen and (max-width: 380px) {
                    font-size: 24px;
                }
            }

            p {
                font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
                font-size: 18px;
                line-height: 28px;
                color: var(--bodyText);
            }
        }

        .aboutUsSection_navBlock  {
            flex-basis: 383px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 1080px) {
    .aboutUsSection {
        &_content {
            flex-direction: column;

            .aboutUsSection_itemsBlock {
                width: 100%;
                margin-top: 16px;
            }

            .aboutUsSection_navBlock {
                flex-basis: auto;
            }
        }
    }
}