.socials_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 580px) {
        margin-top: 18px;
    }

    span {
        margin-right: 3px;
    }

    &__item {
        cursor: pointer;
        background-color: var(--blue);
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}