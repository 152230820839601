.reviewsFilters {
    display: flex;
    flex-direction: column;

    &.reverse {
        flex-direction: column-reverse;
        gap: 24px;

        .companiesRatingFilter,
        .companiesAlphabeticalFilter {
            h4 {
                margin-bottom: 16px;
            }
        }
        .companiesAlphabeticalFilter {
            padding-bottom: 24px;
            border-bottom: 1px solid #ccc;
            p {
                display: none;
            }
        }
    }
}