.individualArticle {
    margin-top: 24px;
    &_content {
        display: flex;
        flex-direction: row;
        gap: 40px;

        .article_content {
            width: 70%;
            flex-grow: 2;
            margin-bottom: 100px;

            @media screen and (max-width: 1000px) {
                margin-bottom: 64px;
            }

            .article_content__header {
                font-size: 32px;
                font-family: SourceSansPro-SemiBold;
                color: var(--orange);
                margin: 32px 0 40px 0;

                @media screen and (max-width: 580px) {
                    font-size: 24px;
                }
            }

            &__socials {
                margin-bottom: 32px;
                border-top: 1px solid #CCC;
                border-bottom: 1px solid #CCC;
                padding: 22px 0;

                font-family: SourceSansPro-Regular;
                font-size: 18px;
                line-height: 28px;
                color: var(--bodyText);

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                @media screen and (max-width: 580px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                @media screen and (max-width: 1280px) {
                    p {
                        padding-right: 10px;
                    }
                }
                @media screen and (max-width: 580px) {
                    p {
                        font-size: 16px;
                    }
                }
            }
            &__article {
                .article_image {
                    margin-bottom: 32px;

                    img {
                        width: 100%;
                    }
                }

                .article_text {
                    font-family: SourceSansPro-Regular;
                    font-size: 18px;
                    line-height: 28px;

                    blockquote {
                        background: var(--lightOrange);
                        font-family: SourceSansPro-SemiBold;
                        font-size: 24px;
                        line-height: 38px;
                        border-top: 1px solid var(--orange);
                        border-bottom: 1px solid var(--orange);
                        margin: 16px 0;

                        @media screen and (max-width: 580px) {
                            font-size: 20px;
                        }
                        p {
                            padding: 32px 42px;
                            text-align: center;

                            @media screen and (max-width: 580px) {
                                padding: 24px 12px;
                            }

                        }
                    }
                }
            }
            &__additionalBox {
                padding: 24px;
                background-color: var(--light-gray3);
                margin-top: 24px;

                span {
                    font-family: SourceSansPro-Regular;
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
        .article_rightRail { 
            flex-basis: 383px;
            flex-grow: 1;
            margin-bottom: 100px;
            margin-top: 52px;
        }

        @media screen and (max-width: 1000px) {
            flex-direction: column;
            gap: 0;

            .article_content {
                width: 100%;
            }
        }
    }
}