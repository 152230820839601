.afRecources {
    &_list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        
        .recourceLink {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            padding: 12px 12px 12px 16px;
            background-color: var(--light-blue2);

            font-size: 16px;
            font-family: SourceSansPro-Regular;
            color: var(--blue);
        }
    }
}