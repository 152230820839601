.commonForm {
    background: var(--lightOrange);
    border: 1px solid var(--grey);
    padding: 24px;

    .formError {
        margin-bottom: 24px;
    }

    form {
        margin-bottom: 16px;
    }

    &_header {
        font-family: SourceSansPro-SemiBold;
        font-size: 24px;
        line-height: 30px;
        color: var(--bodyText);
        margin-bottom: 24px;
    }

    &_agrees {
        box-sizing: border-box;
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        line-height: 24px;
        color: var(--text2);
        margin-top: 16px;
    }

    .input-group.commonFormInput {

        width: 100%;
        min-width: auto;
        margin-bottom: 16px;
    
        label {
            font-family: SourceSansPro-Regular;
            color: var(--bodyText);
            margin-bottom: 8px;
        }
    
        input {   
            margin-right: 0;
            padding: 10px 16px;
            border: 1px solid var(--grey);
            border-radius: 4px;
    
            &::placeholder {
                font-family: SourceSansPro-Regular;
                font-size: 16px;
                color: var(--text2);
            }

            &.error {
                border-color: var(--red500);
            }
        }
    }
}