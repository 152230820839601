.navList {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    li {
        background-color: var(--light-grey);

        a {
            display: block;
            text-decoration: none;
            color: var(--text2);
            font-family: 'SourceSansPro-Regular', Arial, Helvetica, sans-serif;
            font-size: 18px;
            line-height: 28px;
            padding: 10px 24px 10px 24px;

            &.activeItem {
                color: var(--orange);
                border-left: 2px solid var(--orange);
                font-family: 'SourceSansPro-Semibold', Arial, Helvetica, sans-serif;
            }
        }
    }
}