.mobileNavigation {
    display: none;
}

@media screen and (max-width: 1080px) {
    .mobileNavigation {
        position: relative;
        display: block;
        width: 32px;
    
        .hamburgerBtn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 8px;
            cursor: pointer;
            background: transparent;
            height: 18px;
            width: 32px;
            
            &_line {
                width: 28px;
                height: 3px;
                border-radius: 3px;
                background: var(--white);
            }
        }
        
        .navElements {
            display: flex;
            flex-direction: column;
            height: 0px;
            width: 133px;
            transition: all 0.3s ease-in;
            overflow: hidden;
            z-index: 99;

            .navLink_item {
                font-family: SourceSansPro-SemiBold;
                font-size: 16px;
                line-height: 20px;
                color: var(--white);
                text-decoration: none;
                padding: 10px 16px;
                background: none;
            
                &:hover {
                    background: var(--white-opacity-20);
                }
            }
            
            .active {
                background: var(--white-opacity-20);
            }
        }
        .navElements.active {
            padding-top: 20px;
            height: 255px;
            width: 133px;
        }
    }
}