.commonLink {
    font-family: SourceSansPro-Regular;
    font-size: 18px;
    line-height: 27px;
    color: #344689;
    text-decoration: underline;
    cursor: pointer;
}

.textDecoration {
    text-decoration: none;
}