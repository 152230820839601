.borderedSidebar {
    width: 100%;
    border: 1px solid #DDD;
    border-radius: 4px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;

    &_title {
        h3 {
            color: #FD8848;
            font-family: SourceSansPro-SemiBold;
            font-size: 24px;
            line-height: 30px;
        }
    }
    &_subtitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        color: var(--darkText);
        margin-top: 8px;
        
        img {
            margin-left: 8px;
        }
    }

    @media screen and (max-width: 500px) {
        padding: 16px;
    }
}