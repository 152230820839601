.articlesNnews {
    padding: 24px;
    margin-bottom: 16px;
    border: 1px solid #ccc;

    &_header {
        h3 {
            font-family: SourceSansPro-SemiBold;
            font-size: 24px;

            @media screen and (max-width: 580px) {
                font-size: 20px;
            }
        }
    }
    &_content {
        .articleAndNewsItem {
            border-bottom: 1px solid #ccc;
            padding: 16px 0;
            font-family: SourceSansPro-Regular;

            a {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 4px;
                color: var(--blue);
            }
            p {
                font-size: 16px;
                line-height: 24px;
                color: var(--text2);
            }

            &:last-child{
                border-bottom: none;
                padding: 16px 0 0 0;
            }
        }
    }
}