.jobByCategories {
    border-top: 1px solid #ccc;
    padding-top: calc(40px - 24px);
    margin-bottom: 100px;

    .linksList {
        .contentWrapper {
            padding: 0;

            .linksList_container  {
                .linksList_title__text {
                    color: var(--orange);
                    font-size: 24px;
                    margin-bottom: 24px;
                }

                .linksList_list {
                    padding: 24px;
                    background: var(--light-gray4);
                    border: 1px solid #ddd;
                    border-radius: 4px;
                }
            }
        }
    }
}