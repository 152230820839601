
.companiesAlphabeticalFilter {
    h4 {
        color: var(--orange);
        font-size: 20px;
        font-family: SourceSansPro-SemiBold;
        margin-bottom: 8px;
        span {
            margin-left: 14px;
        }
    }
    p {
        color: var(--bodyText);
        font-family: SourceSansPro-Regular;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 16px;
    }
    .companiesAlphabeticalFilter_letters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 12px;

        .symbolItem {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .symbol {
                text-align: center;
                position: relative;
                width: 25px;
                font-family: SourceSansPro-Regular;
                font-size: 18px;
                line-height: 24px;
                color: var(--blue);
                text-decoration: underline;
                cursor: pointer;
                padding-right: 24px;

                &::before {
                    position: absolute;
                    content: '';
                    z-index: 99;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 12px;
                    border-left: 1px solid #292526;
                    height: 13px;
                }

                &:hover {
                    color: var(--orange);
                }
                &.active {
                    color: var(--orange);
                }
            }
        }
    }
}