.reviewBlock {
    margin: 40px 0;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #CCC;

    &_content {
        display: flex;
        flex-direction: column;

        .reviewBlock_header {
            padding-bottom: 13px;
            border-bottom: 1px solid #ccc;
            h4 {
                font-family: SourceSansPro-SemiBold;
                font-size: 20px;
                color: var(--orange);
            }
        }

        .reviewBlock_feedback {
            margin: 16px 0 24px 0;
            display: flex;
            flex-direction: row;
            gap: 24px;

            &__text {
                color: var(--bodyText);
                font-size: 18px;
                line-height: 27px;

                h4 {
                    font-family: SourceSansPro-SemiBold;
                }
                p {
                    font-family: SourceSansPro-Regular;
                }
            }
        }

        .reviewBlock_btn {
            display: flex;
            justify-content: flex-end;
            a {
                padding: 10px 25px;
                background: var(--orange);
                color: var(--white);
                font-family: SourceSansPro-SemiBold;
                font-size: 18px;
                line-height: 27px;
                border-radius: 4px;
                text-decoration: none;
                @media screen and (max-width: 425px) {
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}