.jobSeekerItem {
    width: 380px;
    height: 381px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--white);
    position: relative;
    border: 1px solid rgba($color: black, $alpha: .2);

    .jobSeekerItem_image {
        height: 200px;
        img {
            object-fit: cover;
            height: 200px;
            width: 100%;
        }
    }

    .jobSeekerItem_content {
        padding: 24px;
        border-top: none;
    }

    .jobSeekerItem_header {
        margin-bottom: 8px;
        font-family: SourceSansPro-SemiBold;
        font-size: 24px;
        line-height: 30px;
        color: var(--bodyText);
    }

    .jobSeekerItem_description {
        font-family: SourceSansPro-Regular;
        font-size: 18px;
        line-height: 27px;
        color: var(--bodyText);
        opacity: 0.7;
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 1136px) and (min-width: 698px) {
    .jobSeekerItem {
        width: 100%;
        height: 200px;
        flex-direction: row;

        .jobSeekerItem_image {
            height: 200px;
            width: 352px;
            img {
                height: 200px;
                width: 352px;
                object-fit: cover;
            }
        }
    }
}
@media screen and (max-width: 698px) {
    .jobSeekerItem {
        width: 100%;
        height: auto;
        .jobSeekerItem_image {
            img {
                object-fit: cover;
            }
        }
        .jobSeekerItem_content {
            .jobSeekerItem_header {
                h4 {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }
}